import React from 'react'

const StepsContent = (props) => {
  const { stepIndex, steps } = props

  return(
    <>
      <div className="steps-content">
        {steps[stepIndex].component}
      </div>
    </>
  )
}
export default StepsContent