import React from "react"
import PlusSVG from "../images/plus.inline.svg"
import MinusSVG from "../images/minus.inline.svg"

const Quantity = props => {
  const { quantity, setQuantity } = props
  return (
    <div className="quantity">
      <div
        className="increase"
        onClick={() => {
          setQuantity(quantity + 1)
        }}
      >
        <PlusSVG />
      </div>
      <div
        className="decrease"
        onClick={() => {
          if (quantity > 1) {
            setQuantity(quantity - 1)
          }
        }}
      >
        <MinusSVG />
      </div>
      <input
        className="fs--s"
        type="text"
        pattern="[0-9]*"
        name="quantity"
        min="1"
        value={quantity}
        onChange={() => {}}
      />
    </div>
  )
}

export default Quantity
