import React from 'react'

const UnderlineText = (props) => {
  const {text} = props

  return(
    <div className="underline-text fs--s">
      <span className="text">{text}</span>
      <aside className="underline"></aside>
    </div>
  )
}

export default UnderlineText