import React from "react"
import Card from "./Card"
import CheckSVG from "../images/check.inline.svg"
import getTranslations from "../translations"

const AddOn = props => {
  const { title, description, price, checked, lang } = props
  const t = getTranslations(lang)

  return (
    <label className="add-on">
      <input type="checkbox" name={title} onChange={checked} />
      <Card>
        <header>
          <h4 className="mb--xs">{title}</h4>
        </header>
        <div className="co--light fs--s lh--m mb--s">
          <p>{description}</p>
        </div>
        <footer className="fs--s lh--s">
          <div className="checkmark">
            <CheckSVG />
          </div>
          <span>{`${t.add_on.add} ${title} (+DKK ${price})`}</span>
        </footer>
      </Card>
    </label>
  )
}

export default AddOn
