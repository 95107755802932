import React, {useEffect, useRef, useState} from "react"
import AddOn from "./AddOn"

const ShopStepAddOns = props => {
  const {
    addOnsVariants,
    selectedAddons,
    setSelectedAddOns,
    lang
  } = props

  const addOnsRef = useRef()

  const handleCheck = ((event)=>{
    setSelectedAddOns([])
    const checkboxes = addOnsRef.current.querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach((checkbox,i)=>{
      if(checkbox.checked){
        setSelectedAddOns(selectedAddons => [...selectedAddons, addOnsVariants[i].product])
        console.log(addOnsVariants[i].product, selectedAddons)
      }
    })
  })


  return (
    <>
      <div ref={addOnsRef} className="mb--m">
        {addOnsVariants &&
        addOnsVariants.map((variant, i)=>{
          return(
            <AddOn
              key={i}
              title={variant.title}
              description={variant.description}
              price={variant.product.price}
              checked={handleCheck}
              lang={lang}
            />
          )
        })
        }
      </div>
    </>
  )
}

export default ShopStepAddOns
