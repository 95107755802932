import React, { useContext } from "react"
import get from "lodash/get"
import { RichText } from "prismic-reactjs"
import UnderlineText from "./UnderlineText"
import StoreContext from "../context/StoreContext"
import GuideComponent from "../components/Guide"

const Guidance = props => {
  const context = useContext(StoreContext)
  const { setModalVisibility, setModalContent } = context
  const { guidance, type } = props

  if (!guidance) {
    return false
  }
  const handleClick = (title, text) => {
    setModalVisibility(true)
    setModalContent(title, text, "image")
  }
  return (
    <ul className="guidance" style={{ lineHeight: "220%" }}>
      {guidance.map((guidance, index) => {
        let titleObj = null
        let descriptionObj = null
        let description = null
        switch (type) {
          case "products":
            titleObj = guidance.collections_guidance_title
            descriptionObj = guidance.collections_guidance_description
            break
          case "sizes":
            titleObj = guidance.sizes_guidance_title
            // descriptionObj = guidance.sizes_guidance_description
            description = <GuideComponent />
            break
          case "poles":
            titleObj = guidance.poles_guidance_title
            descriptionObj = guidance.poles_guidance_text
            break
          case "add-ons":
            titleObj = guidance.addons_guidance_title
            descriptionObj = guidance.addons_guidance_description
            break
          default:
            break
        }
        const title = get(titleObj, "[0].text")
        if (descriptionObj) {
          description = RichText.render(descriptionObj)
        }
        return (
          <li
            onClick={() => {
              handleClick(title, description)
            }}
            key={index}
          >
            <div className="fs--s">
              <span className="text" style={{ textDecoration: "underline" }}>
                {title}
              </span>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export default Guidance
