import React, { useState, useEffect } from "react"
import ProductThumb from "./ProductThumb"
import Guidance from "./Guidance"
import get from "lodash/get"

const ShopStepProducts = props => {
  const {
    guidance,
    products,
    productIndex,
    setProductIndex,
    setProductObj,
    setProductImage,
  } = props
  const [selectedProducTitle, setSelectedProductTitle] = useState(null)
  const [selectedProductImage, setSelectedProductImage] = useState(null)

  const handleChange = event => {
    const index = parseInt(event.target.value)
    const selectedProduct = get(products[index], "shopify")
    setProductIndex(index)
    setProductObj(selectedProduct)
    let title = get(selectedProduct, "title")
    let image = get(selectedProduct, "image")
    console.log(image.src)

    if (title) {
      title = title.split(", ")[1]
      setSelectedProductTitle(title)
    }

    if (image) {
      console.log("set")
      setSelectedProductImage(image.src)
    }
  }

  useEffect(() => {
    const selectedProduct = get(products[productIndex], "shopify")
    let title = get(selectedProduct, "title")
    if (title) {
      title = title.split(", ")[1]
      setSelectedProductTitle(title)
    }
  }, [])

  return (
    <>
      <div className="card pb--s mb--m" onChange={handleChange}>
        <div className="mb--xs">
          {products.map((product, index) => {
            return (
              <ProductThumb
                shopifyData={product.shopify}
                key={index}
                index={index}
                checked={index === productIndex ? true : false}
                hideText={true}
                setProductImage={setProductImage}
              />
            )
          })}
        </div>
        <p className="selected-product fs--s mb--m">{selectedProducTitle}</p>
      </div>

      <div style={{ display: "none" }}>
        <Guidance guidance={guidance} type="products" />
      </div>
    </>
  )
}

export default ShopStepProducts
