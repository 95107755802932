import React, {useState} from 'react'
import ChevronDownSVG from '../images/chevron-down.inline.svg'
const Drawer = (props) => {
  const {title, text} = props
  const [open, setOpen] = useState(props.open)
  const handleClick = () => {
    setOpen(!open)
  }
  return(
    <div className={`drawer ${open ? 'open' : ''}`}>
      <header className="drawer-header" onClick={handleClick}>
        <div className="paragraph-max-width">
          <h4>{title}</h4>
        </div>
        <ChevronDownSVG />
      </header>
      <div className={`drawer-text co--light lh--m paragraph-max-width`}>
        {text}
      </div>
    </div>
  )
}

export default Drawer