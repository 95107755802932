import React from "react"
import Guidance from "./Guidance"

const StepsHeader = props => {
  const { stepIndex, steps } = props

  let types = ["products", "sizes", "poles"]

  return (
    <header className="steps-header">
      <div className="steps-header-guidance">
        <Guidance
          guidance={steps[stepIndex].component.props.guidance}
          type={types[stepIndex]}
        />
      </div>
      <h2 className="fs--m">
        {stepIndex + 1}. {steps[stepIndex].name}
      </h2>
    </header>
  )
}

export default StepsHeader
