import React, { useRef } from "react"
import Guidance from "./Guidance"
import SizeInput from "./SizeInput"
import DefaultSize from "./DefaultSize"
import get from "lodash/get"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../prismic/linkResolver"

const ShopStepSizes = props => {
  const {
    description,
    contactDescription,
    defaultSizes,
    guidance,
    maxLength,
    maxWidth,
    minLength,
    minWidth,
    width,
    setWidth,
    length,
    setLength,
    defaultSizeIndex,
    setDefaultSizeIndex,
    lang
  } = props

  const sizeLabels = {
    railWidth: 'Enter rail width',
    floorToCeilingHeight: 'Enter floor-to-ceiling height'
  }

  if (lang === 'da-dk') {
    sizeLabels.railWidth = 'Indtast skinnebredde'
    sizeLabels.floorToCeilingHeight = 'Indtast gulv-til-loft højde'
  }

  const handleDefaultSizeChange = event => {
    const targetValue = parseInt(event.target.value)
    setDefaultSizeIndex(targetValue)
    setLength(defaultSizes[targetValue].default_size_length)
    setWidth(defaultSizes[targetValue].default_size_width)
  }

  const handleSizeChange = event => {
    const targetValue = parseInt(event.target.value)
    const targetName = targetName

    if (event.target.name === "width") {
      setWidth(targetValue)
    } else if (event.target.name === "length") {
      setLength(targetValue)
    }
  }

  const selectInput = event => {
    event.target.setAttribute("type", "text")
    event.target.setSelectionRange(0, 9999)
    event.target.setAttribute("type", "number")
  }

  const handleKeyUp = event => {
    if (event.key === "Backspace") {
      if (event.target.value.length < 2) {
        event.preventDefault()
        selectInput(event)
        event.target.value = ""
      }
    }
  }

  const selectOnFocus = event => {
    selectInput(event)
  }

  const validateOnBlur = event => {
    const targetValue = parseInt(event.target.value)
    const targetName = event.target.name
    const minValue = parseInt(event.target.min)
    const maxValue = parseInt(event.target.max)

    if (targetValue < minValue || !targetValue) {
      if (targetName === "width") {
        setWidth(minValue)
      } else if (targetName === "length") {
        setLength(minValue)
      }
    }

    if (targetValue > maxValue) {
      if (targetName === "width") {
        setWidth(maxValue)
      } else if (targetName === "length") {
        setLength(maxValue)
      }
    }
  }

  return (
    <>
      <div className="card mb--m">
        {description && (
          <div className="article fs--s">
            {RichText.render(description, linkResolver)}
          </div>
        )}
        {/* <Guidance guidance={guidance} type="sizes" />*/}
        {/* <div className="default-sizes" onChange={handleDefaultSizeChange}>
          {defaultSizes.map((defaultSize, index)=>{
            const title = get(defaultSize,'default_size_title[0].text')
            const width = get(defaultSize, 'default_size_width')
            const length = get(defaultSize, 'default_size_length')
            return <DefaultSize title={title} width={width} length={length} key={index} index={index} checked={index === defaultSizeIndex ? true : false} />
          })}
        </div> */}
        <div className="size-inputs">
          <div
            onChange={handleSizeChange}
            onBlur={validateOnBlur}
            onFocus={selectOnFocus}
            onKeyDown={handleKeyUp}
          >
            <SizeInput
              label={sizeLabels.railWidth}
              unit="cm"
              min={minWidth}
              max={maxWidth}
              name="width"
              value={width}
            />
            <div className="min-max article fs--xs co--light">
              Min: {minWidth}cm / Max: {maxWidth}cm
            </div>
          </div>
          <div
            onChange={handleSizeChange}
            onBlur={validateOnBlur}
            onFocus={selectOnFocus}
            onKeyDown={handleKeyUp}
          >
            <SizeInput
              label={sizeLabels.floorToCeilingHeight}
              unit="cm"
              min={minLength}
              max={maxLength}
              name="length"
              value={length}
            />
            <div className="min-max article fs--xs co--light">
              Min: {minLength}cm / Max: {maxLength}cm
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        {contactDescription && (
          <div className="article fs--s">
            {RichText.render(contactDescription, linkResolver)}
          </div>
        )}
      </div>
    </>
  )
}

export default ShopStepSizes
