import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Wrapper from "../components/Wrapper"
import Customizer from "../components/Customizer"
import Grid from "../components/Grid"
import Drawer from "../components/Drawer"
import get from "lodash/get"
import Slices from "../components/Slices"
import CollectionsCarrousel from "../components/CollectionsCarrousel"
import ParseSEOData from "../components/ParseSEOData"
import getTranslations from "../translations"

export const query = graphql`
  query CollectionQuery($uid: String, $lang: String) {
    prismic {
      allCollections(uid: $uid, lang: $lang) {
        edges {
          node {
            title
            description
            heading
            shopify_main_image
            details {
              detail_description
              detail_title
            }
            _meta {
              type
              uid
              lang
            }
            products {
              shopify
            }
            meta_description
            meta_title
            share_image
            _meta {
              uid
              type
              lang
            }
            body {
              ... on PRISMIC_CollectionBodyGallery {
                type
                label
                primary {
                  image_first
                  image_last
                  image_lastSharp {
                    childImageSharp {
                      sizes {
                        aspectRatio
                      }
                    }
                  }
                  image_firstSharp {
                    childImageSharp {
                      sizes {
                        aspectRatio
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Collection = ({ data }) => {
  const doc = data.prismic.allCollections.edges.slice(0, 1).pop()
  if (!doc) return null

  const title = get(doc.node, "title[0].text")
  const heading = get(doc.node, "heading[0].text")
  const description = get(doc.node, "description")
  const products = get(doc.node, "products")
  const details = get(doc.node, "details")
  const uid = get(doc.node, "_meta.uid")
  const lang = get(doc.node, "_meta.lang");
  const shopifyMainImage = get(doc.node, "shopify_main_image");
  const t = getTranslations(lang)

  return (
    <>
      <ParseSEOData data={doc.node} />
      <Customizer
        title={title}
        heading={heading}
        products={products}
        description={description}
        lang={lang}
        shopifyMainImage={shopifyMainImage}
      />
      <div className="collection-intro mb--xl">
        <Wrapper>
          <Grid>
            <header className="collection-header">
              {heading && (
                <div className="paragraph-max-width">
                  <h3 className="fs--m mb--s">{heading}</h3>
                </div>
              )}
              {description && (
                <div className="description co--light lh--m">
                  {RichText.render(description)}
                </div>
              )}
            </header>
            <div className="collection-details">
              {details.map((detail, index) => {
                const title = get(detail.detail_title, "[0].text")
                const text = RichText.render(detail.detail_description)
                let open = false
                // if(index === 0){
                //   open = true
                // }
                return (
                  <Drawer title={title} text={text} key={index} open={open} />
                )
              })}
            </div>
          </Grid>
          {/* <parseSEOData data={doc.node} /> */}
        </Wrapper>
      </div>
      <Slices allSlices={doc.node.body} />
      <CollectionsCarrousel title={t.collections.other_collections} notIn={uid} lang={lang} />
    </>
  )
}

Collection.query = query
export default Collection
