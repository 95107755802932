import React from "react"
import ProductThumb from "./ProductThumb"
import Guidance from "./Guidance"

const ShopStepPoles = props => {
  const { guidance, products, poleIndex, setPoleIndex, setPoleObj, lang } = props

  const handleChange = event => {
    const index = parseInt(event.target.value)
    setPoleIndex(index)
    setPoleObj(products[index].pole)
  }

  return (
    <>
      <div className="card pb--s mb--m" onChange={handleChange}>
        {products.map((product, index) => {
          return (
            <ProductThumb
              shopifyData={product.pole}
              key={index}
              type="pole"
              index={index}
              checked={index === poleIndex ? true : false}
              lang={lang}
            />
          )
        })}
      </div>
      <div style={{ display: "none" }}>
        <Guidance guidance={guidance} type="poles" />
      </div>
    </>
  )
}

export default ShopStepPoles
