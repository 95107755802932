import React from 'react'

const ProgressBar = (props) => {
  const { stepIndex } = props
  return (
    <div className="progress-bar">
      <div className="background" data-step={stepIndex + 1} />
      <ol className="dividers">
        <li/>
        <li/>
        <li/>
      </ol>
    </div>
  )
}

export default ProgressBar