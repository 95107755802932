import React from "react"
import get from "lodash/get"
import ProductImg from "./ProductImage"

const ProductThumb = props => {
  const { shopifyData, type, index, checked, hideText, setProductImage } = props

  const src = get(shopifyData, "image.src")

  let sampleImgSrcThumb = null
  let sampleImgSrcLarge = null
  if (src) {
    sampleImgSrcThumb = src.replace(/(\.[^\.]*)?$/, "_small$1")
    sampleImgSrcLarge = src.replace(/(\.[^\.]*)?$/, "_large$1")
  }
  let title = get(shopifyData, "title")
  if (title && type !== "pole") {
    title = title.split(", ")[1]
  }

  return (
    <label
      className={`product-thumb ${hideText && `hide-text`}`}
      onMouseEnter={() => {
        if (setProductImage) {
          setProductImage(sampleImgSrcLarge)
        }
      }}
      onMouseLeave={() => {
        if (setProductImage) {
          setProductImage()
        }
      }}
      onClick={() => {
        if (setProductImage) {
          setProductImage()
        }
      }}
    >
      <input
        type="radio"
        value={index}
        name={type}
        checked={checked}
        onChange={() => {}}
      />
      {sampleImgSrcThumb && (
        <ProductImg src={sampleImgSrcThumb} title={title} />
      )}
      {title && !hideText && <h5 className="product-title fs--s">{title}</h5>}
    </label>
  )
}

export default ProductThumb
