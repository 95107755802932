import React from "react"
import Pill from "./Pill"
import Quantity from "./Quantity"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"

const OrderSummary = props => {
  const {
    productObj,
    poleObj,
    stepIndex,
    setStepIndex,
    width,
    length,
    productObjVariant,
    quantity,
    setQuantity,
    poleObjVariant,
    selectedAddOns,
  } = props
  let productPrice = parseInt(get(productObjVariant, "price"))
  let polePrice = parseInt(get(poleObjVariant, "price"))

  let price = parseInt(productPrice + polePrice) * quantity

  if (selectedAddOns.length) {
    selectedAddOns.forEach(addOn => {
      price = price + parseInt(addOn.price)
    })
  }

  const activeClass = "active"

  return (
    <div className="">
      <div className="order-line-items">
        <div
          className="order-price"
          style={{ fontSize: "1.2em" }}
        >{`DKK ${price}`}</div>
        <ul className="order-items" style={{ display: "none" }}>
          {width && length && (
            <li
              className={stepIndex === 1 ? activeClass : null}
              onClick={() => {
                setStepIndex(1)
              }}
            >
              <Pill>{`B:${width}CM × H:${length}CM`}</Pill>
            </li>
          )}
          {!isEmpty(productObj) && (
            <li
              className={stepIndex === 0 ? activeClass : null}
              onClick={() => {
                setStepIndex(0)
              }}
            >
              <Pill>{get(productObj, "title")}</Pill>
            </li>
          )}
          {!isEmpty(poleObj) && (
            <li
              className={stepIndex === 2 ? activeClass : null}
              onClick={() => {
                setStepIndex(2)
              }}
            >
              <Pill>{get(poleObj, "title")}</Pill>
            </li>
          )}
        </ul>
      </div>
      <Quantity quantity={quantity} setQuantity={setQuantity} />
    </div>
  )
}

export default OrderSummary
