import React from 'react'

const CustomizerHeader = (props) => {
  const {title, heading} = props
  return(
    <header className="customizer-header">
      <h1 className="customizer-title fs--m lh--s">
        <div className="toggle-collections-sidebar">
          <span className="text">{title}</span>
        </div>
      </h1>
      <p className="co--light lh--m">{heading}</p>
    </header>
  )
}

export default CustomizerHeader