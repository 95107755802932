import React from "react"
import Button from "./Button"

const StepsNav = props => {
  const { stepIndex, setStepIndex, steps, handleAddToCart, adding, lang } = props
  const addToBasketLabel = !lang || lang === 'da-dk' ? 'Læg i kurv' : 'Add to the basket'
  const backToLabel = !lang || lang === 'da-dk' ? 'Tilbage til' : 'Back to'
  return (
    <div>
      <nav className="steps-nav">
        {stepIndex < steps.length - 1 ? (
          <div
            className="next-step"
            style={{ width: "100% !important" }}
            onClick={() => {
              setStepIndex(stepIndex + 1)
            }}
          >
            <Button
              text={`${stepIndex + 2}. ${steps[stepIndex + 1].name}`}
              icon="chevron-right"
              classList="primary center icon-right"
            />
          </div>
        ) : (
          <div className="next-step" onClick={handleAddToCart}>
            <Button
              text={addToBasketLabel}
              classList={`primary center add-to-cart-button ${adding &&
                "loading"}`}
              icon={adding ? "spinner" : "chevron-right"}
            />
          </div>
        )}
      </nav>
      {stepIndex > 0 && (
        <div
          className="prev-step"
          onClick={() => {
            setStepIndex(stepIndex - 1)
          }}
        >
          {backToLabel}: {`${steps[stepIndex - 1].name}`}
        </div>
      )}
    </div>
  )
}
export default StepsNav
