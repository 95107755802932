import React from "react"
import PropTypes from "prop-types"

const SizeInput = props => {
  const { min, max, label, unit, value, name } = props
  return (
    <label className="size-input">
      <span className="label fs--s">{label}</span>
      <div className="input-wrapper">
        <input
          className="fs--s"
          type="number"
          name={name}
          min={min}
          max={max}
          pattern="\d*"
          value={value || ""}
          onChange={() => {}}
        />
        <div className="unit-wrapper">
          <span className="unit fs--xs co--light">{unit}</span>
        </div>
      </div>
    </label>
  )
}

export default SizeInput
